// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.googleLoginScreen {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}

.googleLoginScreen .googleLoginScreenContainer {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    padding: 24px;
    border-radius: 16px;
    border: 1px solid #d0d0d0;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.googleLoginScreenContainer img {
    margin: 8px 0;
    height: 48px;
}

.googleLoginScreenContainer h2 {
    margin: 8px 0;
    font-size: 16pt;
    font-weight: 600;
}

.googleLoginScreenContainer p {
    margin: 8px 0;
    font-size: 12pt;
    font-weight: 400;
}

.googleLoginScreenContainer p.errorMessage {
    font-size: 10pt;
    font-weight: 400;
    color: #808080;
}
`, "",{"version":3,"sources":["webpack://./src/stylesheets/GoogleLoginScreen.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,OAAO;IACP,MAAM;IACN,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,gCAAgC;IAChC,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,uBAAkB;IAAlB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".googleLoginScreen {\n    position: fixed;\n    left: 0;\n    top: 0;\n    width: 100vw;\n    height: 100vh;\n}\n\n.googleLoginScreen .googleLoginScreenContainer {\n    position: absolute;\n    left: 50%;\n    top: 45%;\n    transform: translate(-50%, -50%);\n    padding: 24px;\n    border-radius: 16px;\n    border: 1px solid #d0d0d0;\n    width: fit-content;\n    height: fit-content;\n}\n\n.googleLoginScreenContainer img {\n    margin: 8px 0;\n    height: 48px;\n}\n\n.googleLoginScreenContainer h2 {\n    margin: 8px 0;\n    font-size: 16pt;\n    font-weight: 600;\n}\n\n.googleLoginScreenContainer p {\n    margin: 8px 0;\n    font-size: 12pt;\n    font-weight: 400;\n}\n\n.googleLoginScreenContainer p.errorMessage {\n    font-size: 10pt;\n    font-weight: 400;\n    color: #808080;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
